"use client";

import { createContext, useContext } from "react";
import {
  AutomaticDiscountFragment,
  CurrencyCode,
  CustomCheckoutSetting,
  StoreDetailsFragment,
} from "../../generated/graphql";
import { TLanguage } from "@/i18n/i18n-settings";

export type StoreContextType = Omit<
  StoreDetailsFragment,
  | "currency"
  | "locales"
  | "defaultLocale"
  | "redirects"
  | "customCheckoutSetting"
> & {
  storeId: string;
  hostname: string;
  storeUrl: string;
  currency: CurrencyCode;
  locales: TLanguage[];
  defaultLocale: TLanguage;
  template: string;
  automaticDiscounts: (AutomaticDiscountFragment | null)[];
  customCheckoutSetting:
    | CustomCheckoutSetting
    | Record<string, never>
    | null
    | undefined;
};

const defaultValue: StoreContextType = {
  userId: "",
  id: "",
  storeId: "",
  name: "",
  storeUrl: "",
  hostname: "",
  domain: null,
  logo: null,
  currency: CurrencyCode.Egp,
  locale: "",
  defaultLocale: "en",
  fallbackLocale: "en",
  locales: [],
  appearance: { colors: {}, fonts: [] },
  supportedLocales: [],
  contactInfo: { email: null, phone: null },
  socialMedia: [],
  maintenanceMode: {
    isEnabled: false,
    message: "",
  },
  subscription: null,
  template: "default",
  automaticDiscounts: [],
  integrationScriptControls: [],
  areReviewsActivated: false,
  storeReviewSettings: null,
  customCheckoutSetting: {},
  isWuiltShipmentActive: false,
  activatedWuiltShipmentProviders: [],
};

const StoreContext = createContext<StoreContextType>(defaultValue);

const { Provider } = StoreContext;

const StoreProvider: React.FC<{
  value: StoreContextType;
  children?: React.ReactNode;
}> = ({ value = defaultValue, children }) => {
  return <Provider value={value}>{children}</Provider>;
};

export const useStore = () => useContext(StoreContext);

export { StoreProvider };
